.App {
  display: flex;
  flex-direction: row;
  background: $main-color;
}

.app-container {
  flex: 1;
  background: $main-color url("../images/Background.png") no-repeat;
  background-size: 100%;
  font-family: "exo";
}

.app-content {
  min-height: calc(100vh - 132px);
}

.card {
  border: 2px solid transparent;
  border-radius: 16px;
  background:
    linear-gradient(to right, #121425, #121425),
    linear-gradient(to right, #00fef0 , #bd07c1);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  padding: 32px 26px;
  border-radius: 16px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.modal-header {
  padding: 24px;
  border-bottom: 1px solid #707070;
  h6 {
    font-size: 18px;
    font-weight: 500;
    color: #40b8c5;
  }
}
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 8px;
  height: 50vh;
  .token-list {
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
      margin-top: 2px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .manage-token {
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 8px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #b1e8db;
  }
  .setting {
    padding: 0 24px;
  }
}

.token-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 12px;
  background: #181c33;
  border-radius: 12px;
  margin: 6px 0;
  cursor: pointer;
  .empty-token {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
    background: #b31163;
  }
  img {
    width: 24px;
    height: 24px;
  }
  h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  p {
    font-size: 12px;
    line-height: 14px;
  }
}

.token-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b31163;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: $white;
  margin: 4px;
}

.slippage {
  h4 {
    font-family: "sofia";
    font-size: 20px;
    font-weight: 500;
  }
  .percent {
    margin: 8px 0;
    padding: 4px 16px;
    font-family: "sofia";
    font-size: 18px;
    background: #121425;
    border-radius: 16px;
    &.active {
      background: #260c50;
    }
    &.custom {
      width: 25%;
      padding: 8px;
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100;
}
@media only screen and (max-width: 640px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    min-width: 90vw !important;
  }
}