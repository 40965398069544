.swap {
  &-container {
    margin-top: 30px;
    font-family: "sofia";
    .swap-liquidity {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: $submain-color;
      border: 2px solid #2e335c;
      border-radius: 16px;
      padding: 12px;
      color: $white;
      font-family: "exo";
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      .swap-btn,
      .liquidity-btn {
        border-bottom: 2px solid $white;
        padding: 4px;
      }
      .active {
        border-bottom: 2px solid #d52a2a;
      }
    }

    .swap-liquidity-content {
      .swap-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .swap-panel-card {
          padding: 0;
          font-family: "exo";
          .swap-header {
            padding: 30px 24px 24px;
            border-bottom: 2px solid #6f6e70;
            h4 {
              font-size: 22px;
              font-weight: 600;
              color: $white;
            }
            p {
              font-size: 15px;
              font-weight: 400;
              color: $white;
            }
          }
          .swap-body {
            padding: 40px 26px;
            text-align: center;
            .swap-from {
              background: $submain-color;
              border: 2px solid #14162a;
              border-radius: 16px;
              padding: 8px;
              font-family: "sofia";
              .from-value {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $white;
                margin-bottom: 16px;
                h6 {font-size: 18px;}
                p {font-size: 16px;}
              }
              .from-input {
                display: flex;
                justify-content: space-between;
                padding: 4px;
                align-items: center;
                border-radius: 8px;
                background: #14182c;
                .inputWithButton{
                  position: relative;
                  padding-right: 40px;
                  input {
                    width: 250px;
                    padding: 5px;
                    background: transparent;
                    outline: none;
                    color: $white;
                    font-size: 16px;
                    font-weight: 500;
                  }
                  button {
                    background: transparent;
                    color: #a917f1;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 8px;
                    position: absolute;
                    right: 0;
                  }
                }
                .from-token {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  margin-left: 8px;
                  padding-left: 8px;
                  border-left: 1px solid #293042;
                  p {
                    color: $white;
                    font-size: 16px;
                  }
                }
              }
            }
            .swap-to {
              background: $submain-color;
              border: 2px solid #14162a;
              border-radius: 16px;
              padding: 8px;
              font-family: "sofia";
              .to-value {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $white;
                margin-bottom: 16px;
                h6 {font-size: 18px;}
                p {font-size: 16px;}
              }
              .to-input {
                display: flex;
                justify-content: space-between;
                padding: 4px;
                align-items: center;
                border-radius: 8px;
                background: #14182c;
                .inputWithButton{
                  position: relative;
                  flex: 1;
                  input {
                    width: 100%;
                    padding: 5px;
                    background: transparent;
                    outline: none;
                    color: $white;
                    font-size: 16px;
                    font-weight: 500;
                  }
                  button {
                    background: transparent;
                    color: #a917f1;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 8px;
                    position: absolute;
                    right: 0;
                  }
                }
                .to-token {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  margin-left: 8px;
                  padding-left: 8px;
                  border-left: 1px solid #293042;
                  p {
                    color: $white;
                    font-size: 16px;
                  }
                }
              }
            }
            .reverse-token {
              margin: 16px 0;
              padding: 8px;
              border-radius: 50%;
              background: $submain-color;
            }
            .connect-button,
            .enter-button {
              margin-top: 100px;
              width: 100%;
              background: linear-gradient(to right, #00f9e2, #fe00f9);
              padding: 12px;
              color: $white;
              font-family: "sofia";
              font-size: 20px;
              font-weight: 500;
              border-radius: 16px;
            }
            .exchange-button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              background: linear-gradient(to right, #00f9e2, #fe00f9);
              padding: 12px;
              color: $white;
              font-size: 20px;
              font-weight: 500;
              border-radius: 16px;
              &:disabled {
                background: #4d4756;
                color: #bdbdbd;
              }
            }
            .swap-info {
              margin-top: 18px;
              background: $submain-color;
              border-radius: 16px;
              padding: 16px;
              color: $white;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .liquidity-panel {
        &-card {
          padding: 0;
          .liquidity-header {
            padding: 30px 24px 24px;
            border-bottom: 2px solid #6f6e70;
            h4 {
              font-size: 22px;
              font-weight: 600;
              color: $white;
            }
            p {
              font-size: 15px;
              font-weight: 400;
              color: $white;
            }
            &.add-liquidity-header,
            &.remove-liquidity-header {
              padding: 24px;
              border-bottom: none;
            }
          }
          .liquidity-body {
            text-align: center;
            .warning-connect-wallet {
              padding: 50px 32px;
              color: $white;
              font-size: 22px;
              font-weight: 400;
              line-height: 26px;
              border-bottom: 2px solid #6f6e70;
            }
            .liquidity-inputs {
              padding: 12px 24px;
              .liquidity-input {
                display: flex;
                flex-direction: row;
                flex: 1;
                background: #0d1126;
                border-radius: 12px;
                border: 2px solid #14162a;
                .add-liqidity-token {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 4px 12px;
                  min-width: 180px;
                  img {
                    width: 51px;
                    height: 51px;
                    border-radius: 50px;
                  }
                  .token-name {
                    text-align: left;
                    .add-token {
                      padding: 0 8px;
                      color: #707070;
                      font-size: 10px;
                      border-radius: 16px;
                      border: 1px solid #707070;
                    }
                    p {
                      font-size: 14px;
                      color: #707070;
                      margin-bottom: 2px;
                    }
                    h6 {
                      color: $white;
                      font-size: 15px;
                      font-weight: 400;
                    }
                  }
                }
                .add-liqidity-input {
                  width: 100%;
                  .inputWithButton {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    height: 100%;
                    background: #080808;
                    border-radius: 12px;
                    padding: 0 8px;
                    button {
                      padding: 2px 4px;
                      border: 1px solid #707070;
                      border-radius: 20px;
                      margin-right: 8px;
                      font-size: 10px;
                      color: $white;
                    }
                    input {
                      width: 100%;
                      color: $white;
                      font-size: 18px;
                      font-weight: 400;
                      background: transparent;
                      outline: none;
                    }
                    p {
                      color: #707070;
                      font-size: 10px;
                    }
                  }
                }
              }
            }
            .share-price {
              margin: 24px 18px 0;
              padding: 15px 18px;
              text-align: left;
              background: #080808;
              border-radius: 16px;
              p {
                font-size: 15px;
                color: #707070;
              }
            }
            .approve-liquidity-button,
            .enter-liquidity-button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              padding: 18px;
              font-size: 16px;
              color: #8d8484;
              border: 2px solid #771150;
              border-radius: 16px;
              background: transparent;
            }
            .confirm-liquidity-button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              padding: 18px;
              font-size: 16px;
              color: $white;
              background: #3f1087;
              border-radius: 16px;
              &:disabled {
                background: #4d4756;
                color: #bdbdbd;
                &.insufficient {
                  background: #c1518a;
                  color: #bdbdbd;
                }
              }
            }
            .pair-token-list {
              padding: 24px;
              display: flex;
              flex-direction: column;
              height: 60vh;
              overflow-y: auto;
              .pair-liquidity-tokens{
                border-radius: 12px;
                border: 1px solid #707070;
                background: #121212;
                .pair-liquidity-token {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  background: #260c50;
                  border-radius: 12px;
                  padding: 22px 16px;
                  // border: 1px solid #707070;
                  // margin: 4px 0;
                  .pair-token-logo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 400px;
                    h6 {
                      color: $white;
                      font-size: 20px;
                      font-weight: 400;
                    }
                  }
                  .manage-tokens {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    color: $white;
                  }
                }
                .pool-token-info {
                  margin: 16px 8px 0;
                  padding: 20px 16px;
                  background: #16182e;
                  border-radius: 12px;
                  color: $white;
                }
                .pool-token-control {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                  align-items: center;
                  color: #d52a2a;
                  font-size: 18px;
                  font-weight: 400;
                  a, button {
                    flex: 1;
                    padding: 16px;
                  }
                }
              }
            }
            .remove-liquidity {
              padding: 0 8px 24px;
              &-control {
                margin-bottom: 90px;
                width: 480px;
                text-align: left;
                .remove-liquidity-percent,
                .receive-token {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  padding: 24px;
                  color: $white;
                  border: 1px solid #707070;
                  background: #16182e;
                  border-radius: 12px;
                  .inputWithButton{
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding: 16px 8px 16px 64px;
                    border: 1px solid #d52a2a;
                    border-radius: 12px;
                    background: #080808;
                    input {
                      text-align: right;
                      padding-right: 5px;
                      background: transparent;
                      outline: none;
                      color: $white;
                      font-size: 24px;
                      font-weight: 500;
                    }
                    button {
                      background: #37112d;
                      color: #a917f1;
                      font-size: 12px;
                      font-weight: 500;
                      padding: 8px;
                      border-radius: 8px;
                      position: absolute;
                      margin-left: 16px;
                      left: 0;
                    }
                  }
                  .receive-token-pair {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .recieve-token-amount {
                      background: #080808;
                      border: 1px solid #a818a3;
                      border-radius: 8px;
                      padding: 8px;
                    }
                    img {
                      background: #b31163;
                      width: 46px;
                      height: 46px;
                      margin-right: 4px;
                      border-radius: 50%;
                    }
                  }
                }
              }
              &-button-group {
                display: flex;
                flex-direction: row;
                color: $white;
                justify-content: space-around;
                button {
                  flex: 1;
                  padding: 12px;
                  font-size: 18px;
                  border-radius: 12px;
                }
                .approve-remove-liquidity {
                  background: #120d16;
                  border: 1px solid #a818a3;
                  margin-right: 16px;
                }
                .enter-remove-liquidity {
                  background: #41424a;
                  border: 1px solid #41424a;
                }
                .confirm-remove-liquidity {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #a818a3;
                  border: 1px solid #a818a3;
                  &:disabled {
                    background: #4d4756;
                    color: #bdbdbd;
                    border:none;
                  }
                }
              }
            }
          }
          .liquidity-footer {
            display: flex;
            justify-content: space-around;
            border-top: 1px solid #707070;
            padding: 24px;
            a, button {
              flex: 1;
              padding: 12px;
              font-size: 18px;
              color: $white;
              border-radius: 12px;
              text-align: center;
            }
            .add-liquidity-btn,
            .connect-wallet-btn {
              padding: 10px 0;
              width: 100%;
              button, a {
                width: 100%;
                background: linear-gradient(to right, #00f9e2, #fe00f9);
                padding: 12px;
                color: $white;
                font-size: 20px;
                font-weight: 500;
                border-radius: 16px;
              }
            }
            .add-pair-token {
              border: 1px solid #d52a2a;
              background: #260c50;
              margin-right: 26px;
            }
            .import-pair-token {
              border: 1px solid #41424a;
              background: #41424a;
            }
          }
          .overflow {
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            margin: auto;
            padding: 12px 24px;
            color: $error;
            border: 1px solid $error;
            border-radius: 16px;
            outline-color: $error;
            transform: all 0.5s linear;
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .swap-liquidity-content {
    padding: 36px 0;
  }
  .liquidity-inputs {
    padding: 8px !important;
    .token-name > * {
      font-size: 12px !important;
    }
    .add-liqidity-token {
      min-width: auto !important;
    }
  }
  .pair-liquidity-token {
    flex-direction: column !important;
    .pair-token-logo {
      width: auto !important;
      justify-content: center !important;
    }
  }
  .receive-token-pair {
    img {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .remove-liquidity-percent,
  .receive-token {
    flex-direction: column !important;
  }
  .remove-liquidity-control {
    width: 100% !important;
  }
  .pair-token-list {
    padding: 8px !important;
  }
  .token-logo {
    width: 20px !important;
    height: 20px !important;
    img {
      width: 20px !important;
      height: 20px !important;
    }
  }
  input {
    width: 100% !important;
    padding: 4px !important;
  }
}

@media only screen and (max-width: 400px) {
  .liquidity-input {
    flex-wrap: wrap !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .liquidity-input {
    flex-wrap: wrap !important;
  }
  .pair-liquidity-token {
    flex-direction: column !important;
    .pair-token-logo {
      width: auto !important;
      justify-content: center !important;
    }
  }
  .remove-liquidity-percent,
  .receive-token {
    flex-direction: column !important;
  }
}