.lottery {
  margin: auto;
  &-container {
    padding: 52px 32px;
    margin: auto;
    .aeterna-lottery {
      margin-bottom: 30px;
      .lottery-dashboard {
        background: $submain-color;
        border-radius: 8px;
        .banner-left {
          color: $white;
          h3 {
            font-size: 28px;
          }
          .lottery-prize {
            text-align: center;
            .prize-title {
              margin: auto;
              width: 90px;
              height: 40px;
              font-size: 24px;
              font-weight: 700;
              background: $submain-color;
              border: 3px solid $white;
              border-bottom: none;
              border-radius: 50% / 100% 100% 0 0;
            }
            .prize-value {
              margin: auto;
              padding: 4px 8px;
              border: 3px solid $white;
              border-radius: 12px;
              width: fit-content;
              margin-bottom: 12px;
            }
            .connect-wallet {
              padding: 10px;
              font-size: 14px;
              font-weight: 500;
              border: 2px solid #707070;
              border-radius: 8px;
            }
          }
        }
        .lottery-logo {
          img {
            width: 100%;
            height: auto;
          }
          .buy-ticket-btn {
            background: #803dcf;
            font-size: 22px;
            padding: 10px;
            width: 80%;
            text-align: center;
            border: none;
            color: $white;
            border-radius: 50px;
            margin-top: -48px;
          }
        }
        .banner-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: $white;
          h5 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .ticket-time-track {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: #803dcf;
            .hours,
            .minutes,
            .seconds {
              width: 50px;
              margin: 8px;
              text-align: center;
            }
            h5 {
              background: #171933;
              margin: auto;
              // width: fit-content;
              padding: 12px;
              font-size: 20px;
              font-weight: 500;
              border-radius: 4px;
            }
            p {
              font-size: 12px;
              color: $white;
            }
          }
          .ticket-logo {
            .buy-ticket-number {
              width: 190px;
              padding: 18px 16px 8px 55px;
              z-index: 1;
              p {color: $submain-color;}
              input {
                color: $white;
                background: #696989;
                outline: none;
              }
            }
            img {
              position: absolute;
              top: 0;
            }
            button, a {
              background: #1e64c7;
              color: $white;
              font-size: 16px;
              padding: 8px 12px;
              border-radius: 2px;
              width: fit-content;
              z-index: 2;
            }
          }
        }
      }
      .withdraw-card,
      .buy-ticket-card,
      .history-card {
        background: #0c0d1b;
        border: 1px solid #75257f;
        border-radius: 12px;
        color: $white;
      }
      .withdraw-card {
        padding: 28px 12px;
        .withdraw-img {
          width: 56px;
          height: 56px;
        }
        .winning-value {
          h4 {
            font-size: 14px;
            margin-bottom: 8px;
          }
          .value {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 500;
            background: #22202c;
            padding: 2px;
            border-radius: 4px;
            img {
              width: 13px;
              height: 13px;
            }
          }
        }
        .withdraw-button {
          padding: 16px 28px;
          font-size: 14px;
          font-weight: 500;
          background: #22202c;
          border-radius: 12px;
        }
      }
      .buy-ticket-card,
      .history-card {
        padding: 8px;
        h4 {
          font-size: 12px;
        }
        h3 {
          font-size: 18px;
        }
      }
      .buy-ticket-button {
        font-size: 16px;
        font-weight: 500;
        padding: 16px;
        text-align: center;
        background: #f215d4;
        border-radius: 12px;
      }
    }
    .history-button {
      font-size: 16px;
      font-weight: 500;
      padding: 16px 60px;
      background: #7415f2;
      border-radius: 12px;
    }
    .recent-draw {
      color: $white;
      background: #0f0f33;
      padding: 32px;
      margin-bottom: 24px;
      .all-link {
        padding: 0 4px;
        border: 1px solid #707070;
        border-radius: 12px;
      }
      .recent-draw-carousel {
        .carousel-container {
          margin: auto;
        }
      }
    }
    .how-to-play {
      margin-bottom: 30px;
      h3 {
        font-size: 19px;
        font-weight: 700;
        color: $white;
        margin-bottom: 22px;
        text-align: center;
      }
      .playcard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        text-align: center;
        position: relative;
        height: 260px;
        .flow-logo {
          width: 100%;
          margin-bottom: 0;
        }
        h3 {
          margin-bottom: 14px;
        }
        p {
          font-size: 15px;
          font-weight: 400;
          color: $white;
        }
        img {
          margin: auto;
        }
      }
      .buy-ticket {
        background: linear-gradient(150deg, #bbe1f5, #367cab);
      }
      .wait-draw {
        background: linear-gradient(150deg, #e4207f, #25253c);
      }
      .check-prize {
        background: linear-gradient(150deg, #803dcf, #26263d);
      }
    }

    .lottery-rule {
      h3 {
        font-size: 19px;
        font-weight: 700;
        color: $white;
        margin-bottom: 22px;
        text-align: center;
      }
      &-container {
        background: #191b1e;
        color: $white;
        .match {
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          width: 100px;
        }
        .prize {
          font-size: 11px;
          font-weight: 500;
        }
        .numbers {
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          width: 100px;
        }
        .rule-header {
          display: flex;
          justify-content: space-between;
          border: 1px solid #83c0df;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          font-size: 14px;
        }
        .rule-body {
          .rule-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-left: 1px solid #83c0df;
            border-right: 1px solid #83c0df;
            border-bottom: 1px solid #83c0df;
            padding: 14px 35px;
            .first {
              color: #42ac12;
            }
            .ticket-card {
              position: relative;
              .card-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                top: calc(50% - 15px);
                padding: 0 10px;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .draw-card {
      background: #19193d;
      border-radius: 8px;
      padding: 8px;
      margin: 8px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .round {
        font-size: 11px;
        margin-right: 4px;
        margin-bottom: 0;
        span {
          font-size: 10px;
          padding: 0 2px;
          border: 1px solid #0f0f33;
          border-radius: 2px;
        }
      }
      .draw-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        .datetime {
          margin-bottom: 10px;
          text-align: center;
          .date {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .time {
            font-size: 10px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
        .card-number {
          font-size: 23px;
          font-weight: 600;
        }
        .draw-number {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px;
          border: 1px solid #0f0f33;
          border-radius: 12px;
          margin-bottom: 16px;
        }
        .winner-text {
          color: #96e34c;
        }
        .claim-button {
          width: 80%;
          font-size: 14px;
          font-weight: 500;
          background: #a3ef50;
          padding: 11px;
          border-radius: 8px;
          color: #0f0f33;
          &:hover {
            background: #5fa019;
            transition: 0.3s all linear;
          }
        }
      }
    }
    .past-ticket {
      background: #0f0f33;
      padding: 8px;
      color: $white;
      .date-pagination {
        text-align: center;
        .ticket {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          min-width: 33%;
          background: #2c2b6a;
          border-radius: 16px;
          margin: 0 10px;
          padding: 16px;
          cursor: pointer;
        }
        h3 {
          font-size: 22px;
          font-weight: 500;
        }
        .pagination {
          margin-bottom: 20px;
          .prev-next {
            button {margin: 0 8px;}
          }
        }
        .ticket-list-carousel {
          border-radius: 8px;
          margin: auto;
          margin-bottom: 16px;
          padding: 0 32px 20px 32px;
          background: #343483;
          h4 { font-size: 18px; }
          p { font-size: 15px; }
        }
      }
    }
    .past-draw {
      background: #0f0f33;
      padding: 8px 8px 20px;
      color: $white;
      h3 {
        font-size: 20px;
        margin-bottom: 16px;
      }
      .past-draw-carousel {
        padding: 30px;
        margin: auto;
      }
    }
    .current-ticket {
      background: #0f0f33;
      padding: 8px;
      color: $white;
      h3 {
        font-size: 22px;
        margin-bottom: 32px;
      }
      .current-ticket-carousel {
        border-radius: 8px;
        margin: auto;
        margin-bottom: 16px;
        padding: 84px 32px 44px 32px;
        background: #343483;
      }
      .ticket {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        min-width: 33%;
        background: #2c2b6a;
        border-radius: 16px;
        margin: 8px;
        padding: 16px;
        cursor: pointer;
      }
    }
    .pick-ticket {
      color: $white;
      .picked-ticket {
        p { font-size: 12px; }
        h2 {
          font-size: 28px;
          margin-bottom: 23px;
        }
        h3 { font-size: 24px;}
        .picked-ticket-list {
          max-height: 500px;
          .purchased-ticket {
            background: #343483;
            border-radius: 12px;
            padding: 12px 24px;
            margin-bottom: 16px;
            .title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              h5 { font-size: 19px; }
            }
            .reset-button {
              display: flex;
              padding: 8px 16px;
              font-size: 16px;
              font-weight: 500;
              border: 1px solid #16c286;
              background: #4f37b5;
              border-radius: 8px;
            }
            .ticket {
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              min-width: 33%;
              background: #2c2b6a;
              border-radius: 16px;
              margin: 8px;
              padding: 16px;
              .circle-number {
                p {font-size: 15px;}
              }
            }
          }
        }
      }
      .choose-number {
        .headline {
          display: flex;
          justify-content: center;
          margin: 32px 0;
          p { font-size: 12px;}
        }
        .number-panel {
          background: #343483;
          border-radius: 12px;
          text-align: center;
          .panel-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid #4b55b7;
          }
          .panel-body {
            padding: 24px 16px;
            .numeric {
              cursor: pointer;
              &.picked {
                background: #8b20d2;
                &.final {background: #ff0011;}
              }
            }
            .ticket {
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              min-width: 33%;
              background: #2c2b6a;
              border-radius: 16px;
              margin: 16px;
              padding: 16px;
            }
            .control {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 16px;
            }
            .save-ticket {
              background: #16c386;
              margin: auto;
              padding: 12px 24px;
              color: #2c2b6a;
              border-radius: 12px;
              text-align: center;
              &:disabled {
                background: #a1a1a1;
              }
            }
          }
        }
      }
      .cart-summary {
        margin-top: 84px;
        background: #343483;
        border-radius: 12px;
        border: 2px solid #8a3be1;
        height: fit-content;
        .panel-header {
          padding: 20px;
          border-bottom: 1px solid #4b55b7;
        }
        .panel-body {
          padding: 45px 20px;
          border-bottom: 1px solid #4b55b7;
        }
        .panel-footer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          .buy-ticket {
            background: linear-gradient(to right, #2c2b6a, #2c2b6a), linear-gradient(to right, #00fef0, #bd07c1);
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            padding: 16px;
            margin: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 32px;
            font-weight: 600;
            button {
              &:disabled {
                color: #737a7a;
              }
            }
          }
        }
      }
    }
  }
}

.circle-number {
  position: relative;
  width: 30px;
  height: 30px;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  p {
    font-size: 15px;
    color: $black;
    z-index: 1;
  }
}

@media only screen and (max-width: 425px) {
  .withdraw-card,
  .buy-ticket-card,
  .history-card {
    flex-direction: column !important;
  }
  .rule-header {
    padding: 14px;
  }
  .rule-row {
    padding: 14px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .recent-draw-carousel {
    max-width: 1260px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 1536px) and (max-width: 1700px) {
  .recent-draw-carousel {
    max-width: 1120px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .recent-draw-carousel {
    max-width: 880px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 984px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .recent-draw-carousel {
    max-width: 620px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 720px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .recent-draw-carousel {
    max-width: 370px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 468px;
  }
}


@media only screen and (min-width: 640px) and (max-width: 767px) {
  .recent-draw-carousel {
    max-width: 500px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 540px;
  }
}

@media only screen and (min-width: 520px) and (max-width: 639px) {
  .recent-draw-carousel {
    max-width: 372px;
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 420px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 519px) {
  .recent-draw {
    &.container {
      max-width: 375px;
    }
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 325px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .recent-draw {
    &.container {
      max-width: 270px;
    }
  }
  .ticket-list-carousel,
  .past-draw-carousel,
  .current-ticket-carousel {
    max-width: 270px;
  }
}
